import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

// Layouts
import Layout1 from "@/layout/Layout1";
import { Yetki } from "@/enums";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: "",
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login"),
    },
    {
      path: "/pooldonation/:hash/:code",
      name: "pool",
      component: () => import("@/views/PoolDonation"),
    },
    {
      path: "/",
      redirect: "",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/views/anasayfa/Anasayfa"),
        },
      ],
    },
    {
      path: "/bagis",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/views/bagis/Bagis"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
      ],
    },
    {
      path: "/bagis/basarisiz",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/views/basarisiz_bagis/Bagis"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
      ],
    },
    {
      path: "/kisalink",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/views/link/KisaLink"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
      ],
    },
    {
      path: "/sponsorluk",
      component: Layout1,
      redirect: "/sponsorluk/takip",
      children: [
        {
          path: "takip",
          component: () => import("@/views/sponsorluk/SponsorlukTakibi"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
        {
          path: "safekey",
          component: () => import("@/views/sponsorluk/Safekey"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
        {
          path: "odeme-takip",
          component: () => import("@/views/sponsorluk/OdemeTakip"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
        {
          path: "cekim-log",
          component: () => import("@/views/sponsorluk/CekimLog"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
        {
          path: "havale-takip",
          component: () => import("@/views/sponsorluk/HavaleTakip"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
        {
          path: "tahsilat-dosyasi",
          component: () => import("@/views/sponsorluk/TahsilatDosyasi"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
      ],
    },
    {
      path: "/tanimlar",
      component: Layout1,
      children: [
        {
          path: "sponsorluk",
          component: () => import("@/views/tanimlar/Sponsorluk"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "sponsorluk-detay",
          component: () => import("@/views/tanimlar/SponsorlukDetay"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
      ],
    },
    {
      path: "/iletisim",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/views/iletisim/IletisimYonetimi"),
        },
      ],
    },
    {
      path: "/icerik",
      component: Layout1,
      children: [
        {
          path: "etiket",
          component: () => import("@/views/icerik/Etiket"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "havuzbagis",
          component: () => import("@/views/icerik/HavuzBagis"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR, Yetki.POOL],
            },
          ],
        },
        {
          path: "havuzbagiskampanya",
          component: () => import("@/views/icerik/HavuzBagisKampanya"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "program",
          component: () => import("@/views/icerik/Program"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "kampanya",
          component: () => import("@/views/icerik/Kampanya"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "hizlibagis",
          component: () => import("@/views/icerik/HizliBagis"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "popup",
          component: () => import("@/views/icerik/Popup"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "slider",
          component: () => import("@/views/icerik/Slider"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "haber",
          component: () => import("@/views/icerik/Haber"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "basindabiz",
          component: () => import("@/views/icerik/BasindaBiz"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "kurumsalkimlik",
          component: () => import("@/views/icerik/KurumsalKimlik"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "gelirgider",
          component: () => import("@/views/icerik/GelirGider"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "bagimsizdenetim",
          component: () => import("@/views/icerik/BagimsizDenetim"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "sms",
          component: () => import("@/views/icerik/Sms"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "sikcasorulansorular",
          component: () => import("@/views/icerik/SikcaSorulanSoru"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "galeri/fotograf",
          component: () => import("@/views/icerik/FotoGaleri"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "galeri/video",
          component: () => import("@/views/icerik/VideoGaleri"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "galeri/dokuman",
          component: () => import("@/views/icerik/Dokuman"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "galeri/onlarinhikayesi",
          component: () => import("@/views/icerik/OnlarinHikayesi"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "hakkimizda/hakkimizda",
          component: () => import("@/views/hakkimizda/Hakkimizda"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "hakkimizda/gonullu",
          component: () => import("@/views/hakkimizda/Gonullu"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "hakkimizda/yonetim-kurulu",
          component: () => import("@/views/hakkimizda/YonetimKurulu"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "bildirim",
          component: () => import("@/views/icerik/Bildirim"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
      ],
    },
    {
      path: "/tavsiyerehberi",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/views/tavsiyerehberi/TavsiyeRehberi"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
      ],
    },
    {
      path: "/kullanici",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/views/kullanici/Kullanici"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
      ],
    },
    {
      path: "/bagisci",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/views/bagisci/Bagisci"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
      ],
    },
    {
      path: "/dil",
      component: Layout1,
      children: [
        {
          path: "tanim",
          component: () => import("@/views/dil/Tanim"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "ceviri",
          component: () => import("@/views/dil/Ceviri"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
      ],
    },
    {
      path: "/lokasyon",
      component: Layout1,
      children: [
        {
          path: "ulke",
          component: () => import("@/views/lokasyon/Ulke"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "il",
          component: () => import("@/views/lokasyon/Il"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
        {
          path: "ilce",
          component: () => import("@/views/lokasyon/Ilce"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR],
            },
          ],
        },
      ],
    },
    {
      path: "/finans",
      component: Layout1,
      children: [
        {
          path: "bankabilgi",
          component: () => import("@/views/finans/BankaBilgi"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
        {
          path: "bankahesapbilgi",
          component: () => import("@/views/finans/BankaHesapBilgi"),
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.PROJE],
            },
          ],
        },
      ],
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound,
    },
  ],
});

export default router;
